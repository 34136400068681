<template>
    <b-row>
        <b-col>
            <b-pagination-nav
                v-model="value.currentPage"
                :number-of-pages="totalPages"
                :link-gen="linkGen"
                use-router
            />
        </b-col>
        <b-col>
            <b-form 
                class="d-flex justify-content-end"
                inline
            >
                <label class="mr-2">На странице:</label>
                <b-form-select
                    id="perPageSelect"
                    v-model="value.perPage"
                    :options="perPageVariants"
                />
            </b-form>
        </b-col>
    </b-row>
</template>

<script>

export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        perPageVariants: {
            type: Array,
            default: () => [
                {value: 50, text: "50"},
                {value: 100, text: "100"},
                // {value: 200, text: "200"},
                // {value: 500, text: "500"},
            ],
        },
        linkGen: {
            type: Function,
            required: true,
        },
    },
    computed: {
        totalPages() {
            if (this.value.totalItems <= 0) {
                return 1;
            }

            if (this.value.perPage <= 0) {
                return 1;
            }

            return Math.ceil(this.value.totalItems / this.value.perPage);
        },
    },
}
</script>
